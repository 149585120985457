<template>
  <transition name="fade">
    <div ref="downloadCenter" class="download-center" v-click-outside="onClickOutside">
      <div class="tabs">
        <!-- <div class="tab" :class="{ active: selectedTab === 'manual' }"> -->
          <label class="tab" :class="{ active: selectedTab === 'manual' }">
            <input type="radio" value="manual" v-model="selectedTab">
            {{ $t('download_manual')/*使用手冊*/ }}
          </label>
        <!-- </div> -->
        <!-- <div class="tab" :class="{ active: selectedTab === 'tool' }"> -->
          <label class="tab" :class="{ active: selectedTab === 'tool' }">
            <input type="radio" value="tool" v-model="selectedTab">
            {{ $t('download_tool')/*工具*/ }}
          </label>
        <!-- </div> -->
      </div>
      <div v-if="selectedTab === 'manual'" class="manual-content">
        <div class="content-fix">
          <div class="filter">
            <input v-model="filterManual" type="text" :placeholder="$t('download_search_placeholder')/*搜尋名稱*/">
            <img src="@/assets/icons/feather-search-gray.svg" alt="">
          </div>
          <div class="row">
            <div v-for="(title, index) in manualHeader" :key="index" class="col">{{ title }}</div>
          </div>
        </div>
        
        <div class="manual-list">
          <div v-for="(item, index) in showManualList" :key="index" class="row">
            <div class="col">{{ $t('download_manual')/*使用手冊*/ }}</div>
            <div class="col">{{ item.version }}</div>
            <div class="col"><!-- {{ item.updateTime }} --></div>
            <div class="col"><!-- {{ item.size }} --></div>
            <div class="col">
              <div class="btn" @click="downloadFile(item.archive)">
                <img src="@/assets/icons/file_download.svg" alt="">
                {{ $t('download')/*下載*/ }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="tool-content">
        <div class="content-fix">
          <div class="filter">
            <input v-model="filterTool" type="text" :placeholder="$t('download_search_placeholder')/*搜尋名稱*/">
            <img src="@/assets/icons/feather-search-gray.svg" alt="">
          </div>
          <div class="row">
            <div v-for="(title, index) in toolHeader" :key="index" class="col">{{ title }}</div>
          </div>
        </div>

        <div class="tool-list">
          <div v-for="(item, index) in showToolList" :key="index" class="row">
            <div class="col">
              <el-tooltip popper-class="el-tooltip" :disabled="!item.releaseNote" effect="dark" v-delTabIndex :visible-arrow="false" placement="top">
                <div class="name" @mouseover="onMouseOver(item, index)">
                  <img class="info" src="@/assets/icons/Info_24.svg" alt="">
                  <!--{{ item.name }}-->
                  <span v-html="item.name"></span>
                </div>
                <div v-if="index === hoverIndex" slot="content" class="tooltip-content">
                  <p v-html="item.releaseNote"></p>
                  <p v-if="item.sha256" class="sha">
                    SHA256:
                    {{ item.sha256 }}
                  </p>
                </div>
              </el-tooltip>
            </div>
            <div class="col">
              <img :src="getOsIcon(item.os)" alt="">
              {{ item.os }}
            </div>
            <div class="col">{{ item.version }}</div>
            <div class="col">{{ formatTime(item.updateTime) }}</div>
            <div class="col">{{ onFormatBytes(item.size) }}</div>
            <div class="col">
              <div class="btn" @click="downloadFile(item.url2)">
                <img src="@/assets/icons/file_download.svg" alt="">
                {{ $t('download')/*下載*/ }}
              </div>
              <div class="qr-code">
                <vue-qr v-if="item.url" :text="item.url" :size="120" :margin="10"></vue-qr>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapMutations } from 'vuex'
import { apiGetAppList } from '@/api/index.js'
import vClickOutside from 'v-click-outside'
import VueQr from 'vue-qr'
import axios from 'axios'
import moment from 'moment'
import { formatBytes } from '@/utils/lib.js'

export default {
  name: 'DownloadCenter',
  components: { VueQr },
  data() {
    return {
      selectedTab: 'manual',
      filterManual: '',
      filterTool: '',
      manualHeader: [ 
        this.$t('download_name')/*名稱*/, 
        this.$t('download_version')/*版本*/, 
        this.$t('download_update_time')/*更新時間*/, 
        this.$t('download_size')/*檔案大小*/, 
        '' 
      ],
      toolHeader: [
        this.$t('download_name')/*名稱*/,
        this.$t('download_os')/*作業系統*/,
        this.$t('download_version')/*版本*/, 
        this.$t('download_update_time')/*更新時間*/, 
        this.$t('download_size')/*檔案大小*/, 
        ''
      ],
      manualList: [],
      toolList: [],
      options: {
        width: 120
      },
      hoverIndex: -1,
    }
  },
  computed: {
    showManualList() {
      return this.filterList(this.manualList, this.filterManual)
    },
    showToolList() {
      return this.filterList(this.toolList, this.filterTool)
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  mounted() {
    this.getAppList()
  },
  methods: {
    ...mapMutations(['updateShowDownloadPanel']),
    async getAppList() {
      const res = await apiGetAppList()
      const keys = Object.keys(res.data)

      this.manualList = res.data?.manual?.web
      const toolNames = keys.filter(item => item !== 'manual')
      toolNames.forEach(name => {
        const osList = Object.keys(res.data[name])
        osList.forEach(async(os) => {
          const appArr = res.data[name][os]
          const latestApp = appArr[appArr.length - 1]

          let appConfig = {}
          try {
            const res = await axios.get(latestApp.config)
            appConfig = res.data
            appConfig.note = latestApp.note
          } catch (error) {
            appConfig.version = latestApp.version
            appConfig.url = latestApp.archive
            appConfig.url2 = latestApp.archive
            appConfig.note = latestApp.note
          }

          this.toolList.push({
            ...appConfig,
            name: name,
            os: os,
          })
        })
      })
    },
    getOsIcon(os) {
      if (os === 'android') return require('@/assets/icons/android_icon.svg')
      if (os === 'ios') return require('@/assets/icons/ios_icon.svg')
      if (os.includes('windows')) return require('@/assets/icons/windows_icon.svg')
    },
    filterList(list, filterStr) {
      if (filterStr === '') return list
      else {
        const fStr = filterStr.toLowerCase()
        return list.filter(item => item.name.toLowerCase().includes(fStr) || 
          (item.os && item.os.toLowerCase().includes(fStr)))
      }  
    },
    onClickOutside() {
      this.updateShowDownloadPanel(false)
    },
    downloadFile(archive) {
      let link = document.createElement('a')
      this.$refs.downloadCenter.appendChild(link)
      link.style = 'display: none;'
      link.href = archive
      link.click()
      this.$refs.downloadCenter.removeChild(link)
    },
    async getNote(url) {
      let note = ''
      try {
        const res = await axios.get(url)
        note = res.data.release
      } catch (error) {
        note = this.$t('download_no_info')
      }
      return note
    },
    async onMouseOver(item, index) {
      this.hoverIndex = index
      if (item.releaseNote) return
      const note = await this.getNote(item.note)
      this.$set(item, 'releaseNote', note)
    },
    formatTime(time) {
      if (!time) return ''
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    },
    onFormatBytes(bytes) {
      return bytes && bytes > 0 ? formatBytes(bytes) : ''
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.download-center {
  position: fixed;
  width: 70%;
  height: 60%;
  padding: px2rem(8);
  left: 50px;
  bottom: px2rem(40);
  border-radius: 0 px2rem(8) px2rem(8) 0;
  background: #4A5C78;
  z-index: 5;
}

.tabs {
  display: flex;
  justify-content: flex-end;
  column-gap: px2rem(4);

  .tab {
    width: px2rem(144);
    height: px2rem(38);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    background: #151B3566;
    border-radius: px2rem(8) px2rem(8) 0px 0px;
    font-size: px2rem(24);
    font-weight: 300;
    line-height: px2rem(28);
    user-select: none;
    cursor: pointer;

    input[type="radio"] {
      display: none;
    }
    &.active {
      background: #151B35;
      transition: all 0.3s;
    }

    // &label {
    //   font-size: 24px;
    //   font-weight: 300;
    //   line-height: 28px;
    //   user-select: none;
    //   cursor: pointer;
    // }
  }
}


// .tab input[type="radio"] {
//   display: none;
// }

// .tab.active {
//   background: #151B35;
//   transition: all 0.3s;
// }

// .tab label {
//   font-size: 24px;
//   font-weight: 300;
//   line-height: 28px;
//   user-select: none;
//   cursor: pointer;
// }

.manual-content, .tool-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - px2rem(38));
  background: #151B35;
  border-radius: 0px 0px px2rem(6) 0px;
  color: #ffffff;
}

.manual-list, .tool-list {
  flex: 1;
  overflow-y: overlay;
}

.filter {
  position: relative;
  display: inline-block;
  padding: px2rem(16) px2rem(16) px2rem(6) px2rem(16);
}

.filter input[type="text"] {
  width: px2rem(360);
  height: px2rem(48);
  border: none;
  outline: none;
  border-radius: px2rem(8);
  font-size: px2rem(24);
  font-weight: 300;
  line-height: px2rem(36);
  background: #ffffff;
  padding: px2rem(7) px2rem(48) px2rem(6) px2rem(12);
}

.filter img {
  position: absolute;
  width: px2rem(24);
  height: px2rem(24);
  right: px2rem(28);
  top: px2rem(28);
}

.row {
  display: flex;
  align-items: center;
  column-gap: 3%;
  border-bottom: 1px solid #707070;
  margin-right: px2rem(16);
  padding: px2rem(16);
  font-size: px2rem(24);
}

.row:hover {
  background: #6E7D9333;
}

.row:hover .qr-code {
  filter: blur(0);
}

.row:nth-child(1) {
  font-size: px2rem(20);
  padding: px2rem(10) px2rem(16);
}

.col {
  display: flex;
  align-items: center;
}

.col .content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.col .name {
  display: flex;
  align-items: center;
  font-size: px2rem(24);
}

.tooltip-content > p {
  margin: 0;
  white-space: pre-wrap;
}

.tooltip-content p~p {
  color: #7e7e7e;
  margin-top: px2rem(12);
  font-size: px2rem(16);
}

.manual-content .col:nth-child(1) {
  width: 25%;
}

.manual-content .col:nth-child(2) {
  width: 9%;
}

.manual-content .col:nth-child(3) {
  width: 11%;
}

.manual-content .col:nth-child(4) {
  width: 11%;
}

.manual-content .col:nth-child(5) {
  width: 35%;
  display: flex;
  justify-content: flex-end;
}

.tool-content .col:nth-child(1) {
  width: 24%;
  position: relative;
}

.tool-content .col:nth-child(2) {
  width: 12%;
}

.tool-content .col:nth-child(3) {
  width: 10%;
}

.tool-content .col:nth-child(4) {
  width: 12%;
}

.tool-content .col:nth-child(5) {
  width: 12%;
}

.tool-content .col:nth-child(6) {
  width: 30%;
  min-width: px2rem(240);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: px2rem(40);
}

.col:nth-child(1) img {
  margin-right: px2rem(16);
  cursor: pointer;
}

.col:nth-child(2) img {
  margin-right: px2rem(8);
}

.btn {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: px2rem(20);
  padding: px2rem(3) px2rem(4);
  border-radius: px2rem(8);
  background: #8CC24D;
  cursor: pointer;
}

.btn:hover {
  background: #8CC24D;
}

.btn img {
  margin-right: px2rem(8);
}

.qr-code {
  width: px2rem(120);
  height: px2rem(120);
  filter: blur(5);
}


// @media screen and (max-width: 1600px) {
//   .row {
//     column-gap: 2%;
//     padding: px2rem(12);
//     font-size: 22px;
//   }

//   .col .name {
//     font-size: 22px;
//   }

//   .tool-content .col:nth-child(6) {
//     column-gap: 20px;
//   }
// }
</style>
